export const logError = (...errors) => {
  // eslint-disable-next-line
  console.error(errors)
}
export const handleGqlError = (error) => {
  alert(error);
}
export const debug = (...debug) => {
  // eslint-disable-next-line
  console.info(debug)
}
